export const Card = ({ children }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    {children}
  </div>
);

export const CardHeader = ({ children }) => (
  <div className="p-6 border-b">{children}</div>
);

export const CardTitle = ({ children }) => (
  <h2 className="text-2xl font-bold">{children}</h2>
);

export const CardContent = ({ children, className }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

export const Button = ({ children, onClick, className }) => (
  <button
    onClick={onClick}
    className={`bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors ${className}`}
  >
    {children}
  </button>
);

export const Alert = ({ variant = 'default', children }) => (
  <div className={`p-4 rounded ${variant === 'destructive' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}>
    {children}
  </div>
);

export const AlertDescription = ({ children }) => (
  <p>{children}</p>
); 