import React, { createContext, useState } from 'react';

export const PhotoContext = createContext();

export const PhotoProvider = ({ children }) => {
  const [storedPhoto, setStoredPhoto] = useState(null);
  const [photoAnalysis, setPhotoAnalysis] = useState(null);

  return (
    <PhotoContext.Provider value={{
      storedPhoto,
      setStoredPhoto,
      photoAnalysis,
      setPhotoAnalysis
    }}>
      {children}
    </PhotoContext.Provider>
  );
}; 