import React, { useState, useRef, useEffect, useCallback } from 'react';
import { PhotoContext } from '../../contexts/PhotoContext';
import astrologoImg from '../../assets/images/astrologo.webp';
import mao2Img from '../../assets/images/mao2.webp';
import maoDestinoImg from '../../assets/images/maodestino.webp';
import linhaCabecaImg from '../../assets/images/linhadacabeça.webp';
import linhaVidaImg from '../../assets/images/linhavida.webp';
import linhaAmorImg from '../../assets/images/linhadoamor.webp';
import people1Img from '../../assets/images/people1.webp';
import people2Img from '../../assets/images/people2.webp';
import people3Img from '../../assets/images/people3.webp';
import { ZodiacContext } from '../../contexts/ZodiacContext';

const CompletePalmReadingPage = () => {
  const [showDescriptions, setShowDescriptions] = useState(false);
  const { storedPhoto } = React.useContext(PhotoContext);
  const [showFixedButton, setShowFixedButton] = useState(false);
  const compatibilidadesRef = useRef(null);

  // Estado para armazenar a oferta selecionada
  const [selectedOffer, setSelectedOffer] = useState(null);

  // Função para gerar número aleatório entre 70 e 100
  const getRandomValue = () => Math.floor(Math.random() * (100 - 70 + 1)) + 70;

  const attributes = [
    { name: 'Amor', value: getRandomValue(), color: 'from-rose-400 to-rose-500' },
    { name: 'Saúde', value: getRandomValue(), color: 'from-teal-400 to-teal-500' },
    { name: 'Sabedoria', value: getRandomValue(), color: 'from-amber-400 to-amber-500' },
    { name: 'Carreira', value: getRandomValue(), color: 'from-violet-400 to-violet-500' }
  ];

  const palmLines = [
    {
      name: 'Linha da cabeça',
      color: 'text-amber-400',
      description: 'denota criatividade, idealismo e curiosidade. Sua linha indica uma mente analítica e intuitiva, com forte capacidade de liderança e tomada de decisões.'
    },
    {
      name: 'Linha da vida',
      color: 'text-teal-400',
      description: 'sugere diversos desafios que podem te afetar no futuro. No entanto, sua linha mostra uma forte resiliência e capacidade de superação. Você tem uma energia vital poderosa e uma longevidade promissora. Momentos de transformação significativa estão previstos nos próximos anos.'
    },
    {
      name: 'Linha do coração',
      color: 'text-rose-400',
      description: 'demonstra um bom controle das emoções, estabilidade e comportamento acessível. Sua linha revela uma natureza romântica e leal, com grande capacidade de amar e se doar aos relacionamentos. Você tende a criar conexões profundas e duradouras, mas precisa manter o equilíbrio entre dar e receber.'
    },
    {
      name: 'Linha do destino',
      color: 'text-violet-400',
      description: 'revela que você vai enfrentar desafios na sua carreira durante a vida, bem como em suas habilidades no trabalho. Sua linha indica um caminho profissional não convencional, com mudanças importantes e oportunidades inesperadas. Há indicações de reconhecimento e sucesso, especialmente após superar obstáculos significativos.'
    }
  ];

  const detailedPalmLines = [
    {
      title: 'A linha da cabeça',
      description: <span className="text-[1.1em]">É um espelho da sua inteligência e mentalidade</span>,
      detail: 'Um indicador comprido indica liderança natural',
      color: 'text-amber-400',
      lineColor: '#FFD700'
    },
    {
      title: 'A linha do amor',
      description: <span className="text-[1.1em]">Mostra sua atitude para com o amor e a qualidade do amor</span>,
      detail: 'Um polegar comprido indica boa sorte',
      color: 'text-rose-400',
      lineColor: '#FF9494'
    },
    {
      title: 'A linha da vida',
      description: <span className="text-[1.1em]">Define a qualidade da sua vida e o que você vai conquistar</span>,
      detail: 'Um dedo do meio curto revela um espírito livre',
      color: 'text-teal-400',
      lineColor: '#5EEAD4'
    },
    {
      title: 'A linha do destino',
      description: <span className="text-[1.1em]">Representa sua conquista material e metas da carreira</span>,
      detail: 'Um dedo anelar comprido revela que uma pessoa tem a propensão de se arriscar',
      color: 'text-violet-400',
      lineColor: '#C4B5FD'
    }
  ];

  const testimonials = [
    {
      name: 'Emma',
      image: people1Img,
      text: 'Um excelente aplicativo para você testar um relacionamento com base no signo do zodíaco! Os relatórios de compatibilidade são muito precisos e certeiros. Adorei!'
    },
    {
      name: 'Lucas',
      image: people2Img,
      text: 'Insights incríveis sobre os aspectos da vida e dos relacionamentos. Ótimo trabalho, pessoal, mal posso esperar pelas próximas atualizações.'
    },
    {
      name: 'Sophia',
      image: people3Img,
      text: 'Uau! Esse aplicativo tem muito a oferecer. Gosto bastante do recurso de compatibilidade. Ele me ajudou a analisar minha compatibilidade com um parceiro e a verificar como podemos resolver os desafios em nosso relacionamento. Muito informativo!'
    }
  ];

  const HandImage = ({ highlightLine, imageSrc }) => (
    <div className="relative w-36 h-36 flex-shrink-0">
      <img
        src={imageSrc}
        alt={`Linha ${highlightLine}`}
        className={`w-full h-full object-contain transition-all duration-300 ${
          highlightLine ? 'opacity-100' : 'opacity-80'
        }`}
      />
    </div>
  );

  const ZodiacSection = () => {
    const { userZodiac, zodiacSigns } = React.useContext(ZodiacContext);
    const zodiacInfo = userZodiac ? zodiacSigns[userZodiac] : null;

    const ZodiacCard = ({ sign, element, blur = false }) => (
      <div className="flex flex-col items-center">
        <div className="w-24 h-24 relative mb-2">
          <svg viewBox="0 0 100 100" className="w-full h-full">
            <defs>
              <linearGradient id="cardGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#334155" stopOpacity="0.5" />
                <stop offset="100%" stopColor="#1e293b" stopOpacity="0.8" />
              </linearGradient>
            </defs>
            <rect
              x="10"
              y="10"
              width="80"
              height="80"
              fill="url(#cardGradient)"
              transform="rotate(45 50 50)"
              stroke="#475569"
              strokeWidth="1"
            />
            <text
              x="50"
              y="58"
              textAnchor="middle"
              fill="#e2e8f0"
              fontSize="40"
              dominantBaseline="middle"
              className="font-serif"
            >
              {blur ? '?' : sign}
            </text>
          </svg>
        </div>
        <span className="text-gray-400 text-lg font-medium">
          {blur ? '*****' : element}
        </span>
      </div>
    );

    const ProgressBar = ({ value, color }) => (
      <div className="w-full h-1.5 bg-slate-700/50 rounded-full overflow-hidden">
        <div className={`h-full rounded-full ${color}`} style={{ width: `${value}%` }} />
      </div>
    );

    const CompatibilityMetric = ({ label, value, color }) => (
      <div className="space-y-2">
        <div className="flex justify-between text-gray-300">
          <span>{label}</span>
          <span>{value}%</span>
        </div>
        <ProgressBar value={value} color={color} />
      </div>
    );

    const UnknownSignCard = ({ percentage, blur = false, isMiddle = false }) => (
      <div className="bg-slate-800/50 rounded-lg p-4 text-center border border-slate-700/50">
        <div className="w-12 h-12 mx-auto mb-2 rounded-full bg-slate-700/50 flex items-center justify-center">
          <span className={`text-2xl text-gray-400 ${isMiddle ? 'blur-sm' : ''} transition-all duration-300`}>
            ?
          </span>
        </div>
        <span className={`text-amber-100 ${isMiddle ? 'blur-sm' : ''} transition-all duration-300 block mb-2`}>
          {percentage}%
        </span>
        <button
          onClick={handleCheckoutClick}
          className="px-3 py-1 bg-gradient-to-r from-amber-500 to-amber-600
                    hover:from-amber-600 hover:to-amber-700 text-white rounded-full
                    text-xs font-medium transition-all duration-300
                    hover:-translate-y-0.5 hover:shadow-lg hover:shadow-amber-500/20"
        >
          Descobrir
        </button>
      </div>
    );

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setShowFixedButton(true);
          }
        },
        {
          threshold: 0.1
        }
      );

      if (compatibilidadesRef.current) {
        observer.observe(compatibilidadesRef.current);
      }

      return () => observer.disconnect();
    }, []);

    return (
      <section className="min-h-screen bg-slate-900 py-6 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-serif mb-12 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
            Confira sua compatibilidade amorosa e matrimonial com todos os 12 signos do zodíaco
          </h2>

          <div className="flex justify-center items-center gap-8 mb-12">
            <ZodiacCard
              sign={zodiacInfo?.sign || "?"}
              element={zodiacInfo?.element || "Desconhecido"}
              size="large"
            />
            <div className="flex flex-col items-center">
              <span className="text-2xl text-amber-100 mb-2">100%</span>
              <div className="w-24 h-1 bg-gradient-to-r from-teal-400 to-blue-400" />
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-amber-400 via-purple-400 to-amber-400 rounded-lg blur-md animate-pulse" />
              <div className="relative blur-[6px] bg-slate-900 rounded-lg p-1">
                <ZodiacCard
                  sign="?"
                  element="*****"
                  blur={true}
                />
              </div>
            </div>
          </div>
          <button
            onClick={handleCheckoutClick}
            className="px-8 py-3 bg-gradient-to-r from-amber-500 to-amber-600
                     hover:from-amber-600 hover:to-amber-700 text-white rounded-full
                     text-base font-medium transition-all duration-300
                     hover:-translate-y-0.5 hover:shadow-lg hover:shadow-amber-500/20
                     w-full max-w-md mx-auto block"
          >
            Ver Compatibilidade Completa
          </button>
          <br />
          <br />

          <div className="grid grid-cols-2 gap-8 mb-12">
            <div className="space-y-4">
              <CompatibilityMetric label="Amor" value={90} color="bg-gradient-to-r from-rose-400 to-rose-500" />
              <CompatibilityMetric label="Confiança" value={100} color="bg-gradient-to-r from-amber-400 to-amber-500" />
            </div>
            <div className="space-y-4">
              <CompatibilityMetric label="Comunicação" value={85} color="bg-gradient-to-r from-purple-400 to-purple-500" />
              <CompatibilityMetric label="Casamento" value={100} color="bg-gradient-to-r from-teal-400 to-teal-500" />
            </div>
          </div>

          <h2 ref={compatibilidadesRef} className="text-2xl font-serif text-amber-100 mb-4">
            Outras melhores compatibilidades
          </h2>
          <p className="text-gray-400 mb-8">
            Confira sua compatibilidade amorosa e matrimonial
            com todos os 12 signos do zodíaco
          </p>

          <div className="grid grid-cols-3 gap-4 mb-12">
            <UnknownSignCard percentage={99} />
            <UnknownSignCard percentage={87} />
            <UnknownSignCard percentage={25} />
          </div>

          <div className={`md:hidden fixed bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-slate-900 via-slate-900/95 to-transparent
                          transition-opacity duration-300 z-50 ${showFixedButton ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            <button
              onClick={handleCheckoutClick}
              className="w-full px-12 py-4 bg-gradient-to-r from-teal-400 to-teal-500
                         hover:from-teal-500 hover:to-teal-600 text-white rounded-full
                         text-lg font-medium transition-all duration-300
                         hover:-translate-y-0.5 hover:shadow-lg hover:shadow-teal-500/20"
            >
              Obter previsão pessoal
            </button>
          </div>

          <div className="hidden md:block mb-12">
            <button className="w-full max-w-lg px-12 py-4 bg-gradient-to-r from-violet-400 to-purple-600
                             hover:from-violet-500 hover:to-purple-700 text-white rounded-full text-lg
                             font-medium transition-all duration-300 hover:-translate-y-0.5
                             hover:shadow-lg hover:shadow-violet-500/20">
              Revelar previsões agora
            </button>
          </div>

          <div className="bg-slate-800/30 backdrop-blur-sm rounded-lg p-6 mb-8">
            <h2 className="text-3xl md:text-4xl text-center font-serif mb-8 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
              Sobre Você
            </h2>

            <div className="text-center mb-6">
              <div className="w-24 h-24 relative mb-2 mx-auto">
                <svg viewBox="0 0 100 100" className="w-full h-full">
                  <defs>
                    <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stopColor="#334155" stopOpacity="0.5" />
                      <stop offset="100%" stopColor="#1e293b" stopOpacity="0.8" />
                    </linearGradient>
                  </defs>
                  <rect
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                    fill="url(#iconGradient)"
                    transform="rotate(45 50 50)"
                    stroke="#475569"
                    strokeWidth="1"
                  />
                  <text
                    x="50"
                    y="58"
                    textAnchor="middle"
                    fill="#e2e8f0"
                    fontSize="40"
                    dominantBaseline="middle"
                    className="font-serif"
                  >
                    {userZodiac && zodiacSigns[userZodiac]?.sign || '⭐'}
                  </text>
                </svg>
              </div>
            </div>

            <p className="text-center text-xl md:text-2xl text-amber-100 mt-4">
              {userZodiac || 'Carregando...'}
            </p>

            <p className="text-center mt-6 leading-relaxed text-lg md:text-xl">
              <span className="text-amber-400 font-medium">
                {userZodiac && zodiacSigns[userZodiac]?.description || 'Carregando sua descrição personalizada...'}
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  };

  // Função para recuperar UTMs do localStorage
  const getUTMParameters = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParameters = ['utm_source', 'utm_campaign', 'utm_term', 'utm_content', 'utm_medium', 'aff'];
    let utmData = {};

    utmParameters.forEach(param => {
      if (urlParams.has(param)) {
        utmData[param] = urlParams.get(param);
        localStorage.setItem(param, urlParams.get(param));
      } else if (localStorage.getItem(param)) {
        utmData[param] = localStorage.getItem(param);
      }
    });

    return utmData;
  }, []);

  // Função para construir string de UTM
  const buildUTMString = useCallback((utmData) => {
    const utmParams = [];
    for (const [key, value] of Object.entries(utmData)) {
      if (value) {
        utmParams.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
    return utmParams.length > 0 ? '?' + utmParams.join('&') : '';
  }, []);

  // Função para selecionar oferta aleatória
  const selectRandomOffer = useCallback(() => {
    const offers = [
      { price: 27.90, link: 'https://checkout.perfectpay.com.br/PPU38CP8JNH?hidecard=1' },
      { price: 29.90, link: 'https://checkout.perfectpay.com.br/PPU38CP8JNK?hidecard=1' },
      { price: 33.90, link: 'https://checkout.perfectpay.com.br/PPU38CP8JNL?hidecard=1' }
    ];
    return offers[Math.floor(Math.random() * offers.length)];
  }, []);

  // Efeito para selecionar oferta quando o componente montar
  useEffect(() => {
    setSelectedOffer(selectRandomOffer());
  }, [selectRandomOffer]);

  // Função para gerar URL do checkout com UTMs
  const getCheckoutUrl = useCallback(() => {
    if (!selectedOffer) return '#';
    const utmData = getUTMParameters();
    const utmString = buildUTMString(utmData);

    return selectedOffer.link + (selectedOffer.link.includes('?') ? '&' : '?') + utmString.substring(1);
  }, [selectedOffer, getUTMParameters, buildUTMString]);

  // Função para lidar com o clique no botão
  const handleCheckoutClick = (e) => {
    e.preventDefault();
    const checkoutUrl = getCheckoutUrl();
    if (checkoutUrl !== '#') {
      window.open(checkoutUrl, '_blank');
    }
  };

  // Componente de botão de checkout reutilizável
  const CheckoutButton = ({ children, className }) => (
    <button
      onClick={handleCheckoutClick}
      className={className}
    >
      {children}
    </button>
  );

  return (
    <div className="min-h-screen bg-slate-800 flex items-center justify-center">
      {/* Container responsivo - sem bordas em mobile */}
      <div className="w-full md:w-[390px] bg-slate-900 overflow-hidden relative">
        {/* Notch do celular - só aparece em desktop */}
        <div className="hidden md:block absolute top-0 left-1/2 -translate-x-1/2 h-6 w-40 bg-slate-700 rounded-b-2xl" />

        {/* Conteúdo com scroll */}
        <div className="h-full overflow-y-auto">
          <div className="flex flex-col pb-24 md:pb-0">
            {/* Resultados Iniciais */}
            <section className="min-h-screen bg-slate-900 text-gray-100 py-12 px-4">
              <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl md:text-4xl text-center font-serif mb-12 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
                  Seu relatório de leitura da palma da mão está pronto
                </h1>

                <div className="flex flex-row gap-8 justify-center items-center mb-12">
                  {/* Imagem da Palma */}
                  <div className="flex justify-center items-center">
                    <div className="relative w-48 h-48">
                      {/* Efeito de glow místico */}
                      <div className="absolute inset-0 bg-gradient-to-r from-amber-500/20 via-purple-500/20 to-teal-500/20 rounded-full blur-xl animate-pulse" />
                      <div className="absolute inset-0 bg-gradient-to-t from-amber-500/10 via-purple-500/10 to-teal-500/10 rounded-full blur-lg rotate-180 animate-pulse delay-75" />

                      {/* Container da imagem */}
                      <div className="relative w-full h-full rounded-lg overflow-hidden
                                    bg-gradient-to-br from-amber-900/30 to-slate-900/30
                                    border border-amber-600/30 backdrop-blur-sm
                                    shadow-lg shadow-amber-500/20">
                        <img
                          src={storedPhoto || mao2Img}
                          alt="Palm reading visualization"
                          className="w-full h-full object-cover relative z-10"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Métricas */}
                  <div className="w-64 space-y-6">
                    {attributes.map(attr => (
                      <div key={attr.name} className="space-y-2">
                        <div className="flex justify-between">
                          <span>{attr.name}</span>
                          <span>{attr.value}%</span>
                        </div>
                        <div className="h-2 bg-slate-700 rounded-full overflow-hidden">
                          <div
                            className={`h-full rounded-full bg-gradient-to-r ${attr.color}`}
                            style={{ width: `${attr.value}%` }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-12 space-y-4">
                  {palmLines.map(line => (
                    <p key={line.name} className="text-gray-300">
                      Sua <span className={`font-medium ${line.color}`}>{line.name}</span>{' '}
                      <span className={`${
                        line.name === 'Linha da cabeça' ? '' :
                        showDescriptions ? '' : 'blur-sm select-none'
                      } transition-all duration-300`}>
                        {line.description}
                      </span>
                    </p>
                  ))}

                  <CheckoutButton
                    className={`mt-8 mb-4 px-8 py-4 rounded-full bg-gradient-to-r from-amber-500/20 to-amber-600/20
                                border border-amber-500/30 text-amber-300 hover:text-amber-200
                                transition-all duration-300 hover:shadow-lg hover:shadow-amber-500/20
                                text-lg font-medium w-full max-w-md mx-auto block
                                ${showDescriptions ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}
                  >
                    Revelar Previsões
                  </CheckoutButton>
                </div>
              </div>
            </section>

            {/* Seção do Zodíaco */}
            <ZodiacSection />

            {/* Detalhes das Linhas */}
            <section className="min-h-screen bg-slate-900 py-12 px-4">
              <div className="max-w-4xl mx-auto">
                <h2 className="text-3xl md:text-4xl text-center font-serif mb-16 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
                  O que suas mãos e seus dedos dizem sobre você?
                </h2>

                <div className="space-y-12">
                  {detailedPalmLines.map((line, index) => {
                    const lineName = line.title.split(' ').pop().toLowerCase();

                    // Função para determinar qual imagem usar
                    const getLineImage = () => {
                      switch(lineName) {
                        case 'cabeça':
                          return linhaCabecaImg;
                        case 'vida':
                          return linhaVidaImg;
                        case 'amor':
                          return linhaAmorImg;
                        case 'destino':
                          return maoDestinoImg;
                        default:
                          return mao2Img; // imagem padrão para outras linhas
                      }
                    };

                    return (
                      <div key={index} className="flex justify-center">
                        <div className={`flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} items-start gap-6 max-w-3xl w-full`}>
                          <HandImage
                            highlightLine={lineName}
                            imageSrc={getLineImage()}
                          />
                          <div className="space-y-3 flex-1">
                            <h3 className={`${line.color} font-medium text-lg`}>
                              {line.title}
                            </h3>
                            <p className="text-gray-300 text-sm leading-relaxed">{line.description}</p>
                            <p className="text-gray-400 text-xs italic">• {line.detail}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            {/* Testimonials */}
            <section className="min-h-screen bg-slate-900 py-12 px-4">
              <div className="max-w-4xl mx-auto">
                <h2 className="text-3xl font-serif text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
                  Aqui estão algumas histórias de usuários do Astroline:
                </h2>

                <div className="space-y-8 max-w-lg mx-auto">
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="bg-slate-800/50 backdrop-blur-sm rounded-xl p-6 text-center">
                      {/* Imagem centralizada e maior */}
                      <div className="flex justify-center mb-4">
                        <img
                          src={testimonial.image}
                          alt={testimonial.name}
                          className="w-20 h-20 rounded-full border-2 border-amber-500/20"
                        />
                      </div>

                      {/* Nome centralizado */}
                      <h3 className="text-white text-lg font-medium mb-2">{testimonial.name}</h3>

                      {/* Estrelas centralizadas */}
                      <div className="flex justify-center gap-1 mb-4">
                        {[...Array(5)].map((_, i) => (
                          <span key={i} className="text-amber-400 text-lg">★</span>
                        ))}
                      </div>

                      {/* Texto do depoimento */}
                      <p className="text-gray-300">{testimonial.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {/* Seção de Insights */}
            <section className="min-h-screen bg-slate-900 py-12 px-4">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-serif mb-12 text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
                  Obtenha insights de especialistas
                </h2>

                <div className="bg-slate-800/50 backdrop-blur-sm rounded-xl p-8 max-w-lg mx-auto">
                  <div className="flex justify-center mb-8">
                    <img
                      src={astrologoImg}
                      alt="Astrólogo"
                      className="w-40 h-40 rounded-full border-2 border-slate-800"
                    />
                  </div>

                  <p className="text-gray-300">
                    Nossos astrólogos criam leituras astrológicas personalizadas para seu crescimento e transformação.
                  </p>
                </div>

                <div className="mt-20 mb-12">
                  <h3 className="text-2xl font-serif text-transparent bg-clip-text bg-gradient-to-r from-amber-200 via-amber-100 to-amber-200">
                    Encontre respostas para suas maiores perguntas com mais de 2,3 milhões de pessoas no Astroline
                  </h3>
                </div>

                <div className="mb-12">
                  <img
                    src={mao2Img}
                    alt="Mão com linhas de quiromancia"
                    className="max-w-[75%] h-auto rounded-full shadow-lg mx-auto"
                  />
                </div>
                <CheckoutButton
                  className="w-full max-w-lg px-12 py-4 bg-gradient-to-r from-violet-400 to-purple-600
                             hover:from-violet-500 hover:to-purple-700 text-white rounded-full text-lg
                             font-medium transition-all duration-300 hover:-translate-y-0.5
                             hover:shadow-lg hover:shadow-violet-500/20"
                >
                  Revelar previsões agora
                </CheckoutButton>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletePalmReadingPage;