import React, { createContext, useState, useEffect } from 'react';

export const ZodiacContext = createContext();

export const ZodiacProvider = ({ children }) => {
  const [userZodiac, setUserZodiac] = useState(() => {
    try {
      const saved = localStorage.getItem('userZodiac');
      console.log('ZodiacContext - Carregando do localStorage:', saved);
      return saved || null;
    } catch (error) {
      console.error('Erro ao carregar do localStorage:', error);
      return null;
    }
  });

  const zodiacSigns = {
    'Áries': {
      sign: '♈',
      element: 'Fogo',
      description: "Você é pura energia e determinação, um verdadeiro líder que ilumina qualquer caminho por onde passa. Com sua coragem inigualável, você transforma desafios em oportunidades, inspirando todos ao seu redor a seguirem sua paixão. Áries não espera a vida acontecer; ele a cria. E você, está pronto(a) para descobrir todo o poder que já está dentro de você?"
    },
    'Touro': {
      sign: '♉',
      element: 'Terra',
      description: "Estável, confiável e cheio de amor pela vida, você tem a habilidade de transformar qualquer ambiente em um refúgio de paz e beleza. Sua determinação silenciosa é sua maior força, guiando você em direção aos seus sonhos mais ousados. Você é o porto seguro que todos desejam ter. Descubra como sua essência única pode atrair ainda mais prosperidade e felicidade."
    },
    'Gêmeos': {
      sign: '♊',
      element: 'Ar',
      description: "Você é a alma da festa e a mente mais curiosa da sala, sempre pronto para aprender, compartilhar e conectar. Com sua versatilidade, nenhum dia é igual ao outro. Sua comunicação cativante e adaptabilidade abrem portas que outros nem conseguem enxergar. Está na hora de usar seu brilho natural para alcançar seus objetivos e viver a vida como você merece."
    },
    'Câncer': {
      sign: '♋',
      element: 'Água',
      description: "Você é puro coração, sempre guiado pela intuição e empatia. Sua capacidade de criar laços profundos é inigualável, e sua presença traz conforto e segurança para todos que têm a sorte de estar ao seu lado. Com sua sensibilidade, você transforma o mundo ao seu redor. É hora de explorar como esse dom único pode iluminar sua vida e de quem você ama."
    },
    'Leão': {
      sign: '♌',
      element: 'Fogo',
      description: "Você nasceu para brilhar, Leão! Seu carisma é magnético, e sua confiança inspira todos que cruzam seu caminho. Com um coração generoso e um espírito inabalável, você está sempre pronto para liderar com coragem e paixão. O mundo precisa da sua luz, e agora é o momento de explorar todo o seu potencial e conquistar o que sempre foi seu por direito."
    },
    'Virgem': {
      sign: '♍',
      element: 'Terra',
      description: "Detalhista, organizado e sempre pronto para fazer a diferença, você transforma o ordinário em extraordinário. Sua mente analítica e desejo de ajudar tornam você indispensável. Sua dedicação é o que move montanhas e cria resultados duradouros. Descubra como seu perfeccionismo pode ser a chave para desbloquear uma vida de realizações e bem-estar."
    },
    'Libra': {
      sign: '♎',
      element: 'Ar',
      description: "Você é o equilíbrio que todos procuram, um verdadeiro amante da harmonia e da beleza. Seu charme natural e seu senso de justiça fazem de você uma presença irresistível. Onde você está, existe serenidade e elegância. Está na hora de descobrir como essas qualidades podem atrair ainda mais abundância e conexões profundas para sua vida."
    },
    'Escorpião': {
      sign: '♏',
      element: 'Água',
      description: "Intenso, apaixonado e misterioso, você possui uma profundidade que poucos conseguem alcançar. Sua determinação e lealdade fazem de você uma força a ser reconhecida. Você não apenas vive a vida, você a sente com cada fibra do seu ser. Descubra como canalizar esse poder magnético para transformar sua vida e alcançar o que sempre desejou."
    },
    'Sagitário': {
      sign: '♐',
      element: 'Fogo',
      description: "Você é a definição de liberdade e otimismo, sempre pronto para a próxima aventura. Sua sede por conhecimento e novas experiências faz de você um espírito inspirador. Sagitário não apenas sonha, ele realiza. Está na hora de usar seu entusiasmo natural para explorar novas possibilidades e viver uma vida cheia de significado."
    },
    'Capricórnio': {
      sign: '♑',
      element: 'Terra',
      description: "Ambicioso, disciplinado e incrivelmente resiliente, você tem o poder de transformar qualquer sonho em realidade. Sua dedicação e paciência são sua maior força, guiando você para conquistas que outros apenas imaginam. O mundo reconhece a sua grandeza. Agora é o momento de usar essas habilidades únicas para alcançar ainda mais sucesso."
    },
    'Aquário': {
      sign: '♒',
      element: 'Ar',
      description: "Você é inovador, original e movido por ideias grandiosas. Sua mente está sempre à frente, buscando soluções e impactando o mundo de maneira positiva. Aquário, você não apenas se destaca, você redefine o que significa ser único. Descubra como sua visão futurista pode abrir portas para uma vida cheia de propósito e realizações."
    },
    'Peixes': {
      sign: '♓',
      element: 'Água',
      description: "Sonhador, compassivo e extremamente criativo, você tem a capacidade de ver beleza onde ninguém mais vê. Sua intuição e empatia fazem de você um farol de luz para aqueles ao seu redor. Peixes não segue o fluxo, ele o cria. Está na hora de explorar como sua alma inspiradora pode transformar sua vida em algo verdadeiramente mágico."
    }
  };

  const getZodiacSign = (day, month) => {
    const numDay = parseInt(day, 10);
    const numMonth = parseInt(month, 10);

    console.log('Validando data:', {
      inputDay: day,
      inputMonth: month,
      parsedDay: numDay,
      parsedMonth: numMonth
    });

    if (isNaN(numDay) || isNaN(numMonth) ||
        numDay < 1 || numDay > 31 ||
        numMonth < 1 || numMonth > 12) {
      console.error('Data inválida:', {
        dia: numDay,
        mês: numMonth,
        razão: {
          diaInvalido: isNaN(numDay) || numDay < 1 || numDay > 31,
          mesInvalido: isNaN(numMonth) || numMonth < 1 || numMonth > 12
        }
      });
      return null;
    }

    console.log('Calculando signo para:', {
      dia: numDay,
      mês: numMonth
    });

    if ((numMonth === 3 && numDay >= 21) || (numMonth === 4 && numDay <= 19)) return 'Áries';
    if ((numMonth === 4 && numDay >= 20) || (numMonth === 5 && numDay <= 20)) return 'Touro';
    if ((numMonth === 5 && numDay >= 21) || (numMonth === 6 && numDay <= 20)) return 'Gêmeos';
    if ((numMonth === 6 && numDay >= 21) || (numMonth === 7 && numDay <= 22)) return 'Câncer';
    if ((numMonth === 7 && numDay >= 23) || (numMonth === 8 && numDay <= 22)) return 'Leão';
    if ((numMonth === 8 && numDay >= 23) || (numMonth === 9 && numDay <= 22)) return 'Virgem';
    if ((numMonth === 9 && numDay >= 23) || (numMonth === 10 && numDay <= 22)) return 'Libra';
    if ((numMonth === 10 && numDay >= 23) || (numMonth === 11 && numDay <= 21)) return 'Escorpião';
    if ((numMonth === 11 && numDay >= 22) || (numMonth === 12 && numDay <= 21)) return 'Sagitário';
    if ((numMonth === 12 && numDay >= 22) || (numMonth === 1 && numDay <= 19)) return 'Capricórnio';
    if ((numMonth === 1 && numDay >= 20) || (numMonth === 2 && numDay <= 18)) return 'Aquário';
    if ((numMonth === 2 && numDay >= 19) || (numMonth === 3 && numDay <= 20)) return 'Peixes';

    return 'Signo não encontrado';
  };

  useEffect(() => {
    try {
      if (userZodiac) {
        localStorage.setItem('userZodiac', userZodiac);
        console.log('ZodiacContext - Salvando no localStorage:', userZodiac);
      } else {
        localStorage.removeItem('userZodiac');
        console.log('ZodiacContext - Removendo do localStorage');
      }
    } catch (error) {
      console.error('Erro ao salvar no localStorage:', error);
    }
  }, [userZodiac]);

  const clearZodiac = () => {
    setUserZodiac(null);
    localStorage.removeItem('userZodiac');
    console.log('ZodiacContext - Signo limpo');
  };

  return (
    <ZodiacContext.Provider value={{
      userZodiac,
      setUserZodiac,
      zodiacSigns,
      getZodiacSign,
      clearZodiac
    }}>
      {children}
    </ZodiacContext.Provider>
  );
};