import React, { useEffect } from 'react';
import { Check, Rocket, Code, Zap, Shield, Star, Activity, Database, Layout, Cpu, Cloud, Settings, Users, Crown, Lock, CreditCard } from 'lucide-react';
import { FacebookPixel } from '../../utils/FacebookPixel';

function LandingPage() {
  useEffect(() => {
    FacebookPixel.pageView();
  }, []);

  const handleClick = () => {
    FacebookPixel.track('StartProject', {
      content_name: 'Started Project',
      content_category: 'Project',
      value: 0.00,
      currency: 'BRL'
    });
    // ... resto da lógica do botão
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-purple-600 via-blue-600 to-indigo-700">
        <div className="container mx-auto px-6 py-24 text-center">
          <span className="bg-white/10 text-white px-4 py-1 rounded-full text-sm font-medium mb-6 inline-block">
            Desenvolvimento Web + IA = Inovação
          </span>
          <h1 className="mb-6 text-4xl md:text-6xl font-bold text-white leading-tight">
            Transforme suas Ideias em <br/>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-pink-400">
              Realidade Digital
            </span>
          </h1>
          <p className="mb-8 text-xl text-blue-100 max-w-2xl mx-auto">
            Desenvolvimento web profissional potencializado por IA.
            Entregamos em 2 semanas o que equipes tradicionais fazem em 2 meses.
          </p>
          <p className="mt-8 text-blue-100 max-w-2xl mx-auto">
            Software House. We build easy to use Apps, SaaS, Web Tools & Digital Robots.
          </p>
          <br />
          <div className="flex gap-4 justify-center">
            <button
              onClick={handleClick}
              className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
            >
              Começar Projeto
            </button>
            <button className="border-2 border-white text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition-colors">
              Ver Demonstração
            </button>
          </div>
        </div>
      </div>

      {/* Estatísticas */}
      <div className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            {[
              { value: "60%", label: "Mais Econômico" },
              { value: "2x", label: "Mais Rápido" },
              { value: "100%", label: "Código Limpo" },
              { value: "Consultoria Personalizada", label: "Consultoria Personalizada" }
            ].map((stat, i) => (
              <div key={i}>
                <div className="text-3xl font-bold text-blue-400 mb-2">{stat.value}</div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Portfólio */}
      <div className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <span className="bg-blue-100 text-blue-600 px-4 py-1 rounded-full text-sm font-medium mb-6 inline-block">
              Nossos Projetos
            </span>
            <h2 className="text-4xl font-bold mb-4">Portfólio</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Conheça alguns dos projetos que desenvolvemos com excelência e inovação
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Daddys",
                description: "Plataforma de relacionamento com aplicação web para análise de perfis do Instagram que simula uma avaliação para matching com daddys. Possui landing page para entrada do perfil, tela de análise com animações e resultados.",
                tech: [
                  "React",
                  "TailwindCSS",
                  "React Router",
                  "Axios",
                  "Flask",
                  "Cache de imagens",
                  "Instagram API",
                  "Automação de scrape"
                ],
                image: "https://via.placeholder.com/600x400",
                category: "Relacionamento"
              },
              {
                title: "Mapa do destino",
                description: "Quiz astrológico interativo com análise de quiromancia que oferece leituras personalizadas baseadas em informações do usuário e foto da palma da mão. Possui landing page, fluxo de 11 telas com formulários interativos, captura de imagem com análise em tempo real e relatório final detalhado de compatibilidade e previsões.",
                tech: [
                  "React",
                  "TailwindCSS",
                  "React Router",
                  "MediaPipe/TensorFlow",
                  "Shadcn/ui",
                  "Geoapify API",
                  "IPAPI",
                  "Context API",
                  "Lucide React"
                ],
                image: "https://via.placeholder.com/600x400",                category: "Astrologia"
              },
              {
                title: "Dashboard Analytics",
                description: "Painel administrativo com métricas e análises em tempo real",
                tech: ["React", "TypeScript", "Charts"],
                image: "https://via.placeholder.com/600x400",
                category: "Analytics"
              }
            ].map((project, i) => (
              <div
                key={i}
                className="group relative bg-white rounded-lg overflow-hidden transform hover:-translate-y-1 hover:shadow-xl transition-all duration-300"
              >
                <div className="relative h-56 overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <span className="absolute top-4 left-4 bg-white/90 backdrop-blur-sm text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                    {project.category}
                  </span>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2 group-hover:text-blue-600 transition-colors">
                    {project.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    {project.description}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {project.tech.map((tech, j) => (
                      <span
                        key={j}
                        className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-xs font-medium hover:bg-blue-100 hover:text-blue-600 transition-colors cursor-pointer"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="absolute top-0 right-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-blue-600 hover:text-white transition-colors">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <button className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-3 rounded-lg font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5">
              Ver Mais Projetos
            </button>
          </div>
        </div>
      </div>

      {/* Como Funciona */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Como Funciona</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Nosso processo é simples e transparente, combinando expertise humana com
              inteligência artificial para entregar resultados excepcionais
            </p>
          </div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                icon: Layout,
                title: "Briefing",
                desc: "Entendemos suas necessidades e definimos o escopo"
              },
              {
                icon: Code,
                title: "Desenvolvimento",
                desc: "Codificação ágil com IA e revisão humana"
              },
              {
                icon: Shield,
                title: "Testes",
                desc: "Garantia de qualidade e performance"
              },
              {
                icon: Rocket,
                title: "Entrega",
                desc: "Deploy e suporte contínuo"
              }
            ].map((step, i) => (
              <div key={i} className="text-center">
                <div className="bg-blue-50 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                  <step.icon className="w-8 h-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Planos */}
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Planos e Complexidades</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Escolha o plano ideal para seu projeto. Preços transparentes com entregas garantidas.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                title: "Pockets",
                price: "999",
                icon: Zap,
                color: "green",
                popular: false,
                features: [
                  "Landing Pages",
                  "Design Responsivo",
                  "SEO Básico",
                  "Prazo de entrega até 3 dias",
                  "Estamos entregando em 1 dia!"
                ]
              },
              {
                title: "Complexidade Baixa",
                price: "1.999",
                icon: Star,
                color: "blue",
                popular: true,
                features: [
                  "Tudo do plano Pockets",
                  "Integrações API",
                  "Components & animations",
                  "ChatGPT prompts for terms & privacy",
                  "Cache System",
                  "Prazo de entrega até 5 dias",
                  "Estamos entregando em 2 dias!"
                ]
              },
              {
                title: "Complexidade Média",
                price: "4.499",
                icon: Crown,
                color: "purple",
                popular: false,
                features: [
                  "Tudo do plano Baixa",
                  "Banco de Dados",
                  "Autenticação",
                  "Análise Avançada",
                  "Múltiplas Integrações",
                  "Escalabilidade",
                  "Prazo de entrega de 7 a 12 dias",
                  "Estamos entregando em 5 dias!"
                ]
              }
            ].map((plan, i) => (
              <div key={i}
                className={`bg-white rounded-2xl shadow-xl overflow-hidden transform hover:-translate-y-1 transition-transform duration-300 ${
                  plan.popular ? 'ring-2 ring-blue-600 scale-105' : ''
                }`}
              >
                {plan.popular && (
                  <div className="bg-blue-600 text-white text-center py-2 text-sm font-semibold">
                    MAIS POPULAR
                  </div>
                )}
                <div className="p-8">
                  <div className="flex justify-between items-center mb-6">
                    <plan.icon className={`w-12 h-12 text-${plan.color}-500`} />
                    <div className="text-right">
                      <div className="text-center mb-8">
                        <div className="text-4xl font-bold mb-2">
                          R${plan.price}
                        </div>
                        <div className="text-sm text-gray-500">
                          setup inicial
                        </div>
                      </div>
                      <br />
                      <div className="flex gap-4 justify-center">
                        <button className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-blue-600 hover:text-white transition-colors">
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                        </button>
                        <button className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-lg hover:bg-blue-600 hover:text-white transition-colors">
                          Selecionar Plano
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3 className="text-2xl font-bold mb-6">{plan.title}</h3>
                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature, j) => (
                      <li key={j} className="flex items-center gap-3">
                        <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className={`text-gray-600 ${feature.includes('Estamos entregando') ? 'text-red-500 font-bold animate-bounce' : ''}`}>
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Tecnologias */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Tecnologias Utilizadas</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Trabalhamos com as tecnologias mais modernas do mercado para garantir
              o melhor desempenho e escalabilidade para seu projeto
            </p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { icon: Code, label: "React.js" },
              { icon: Database, label: "Node.js" },
              { icon: Layout, label: "Tailwind" },
              { icon: Settings, label: "Python" },
              { icon: Shield, label: "TypeScript" },
              { icon: Activity, label: "Firebase" },
              { icon: Lock, label: "Autenticação" },
              { icon: CreditCard, label: "Pagamentos" }
            ].map((tech, i) => (
              <div key={i} className="flex flex-col items-center p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
                <tech.icon className="w-12 h-12 text-blue-600 mb-4" />
                <span className="font-semibold">{tech.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Depoimentos */}
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">O que Dizem Nossos Clientes</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Confira a experiência de quem já transformou suas ideias em realidade conosco
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Carlos Silva",
                role: "CEO, TechStart",
                content: "Impressionante como entregaram meu e-commerce em apenas 2 semanas. A qualidade superou minhas expectativas."
              },
              {
                name: "Ana Martins",
                role: "Fundadora, BeautyApp",
                content: "O processo foi super transparente e o resultado final ficou exatamente como eu imaginava. Recomendo!"
              },
              {
                name: "Pedro Santos",
                role: "Marketing, FoodTech",
                content: "A melhor decisão que tomamos foi escolher vocês para desenvolver nossa plataforma. Profissionalismo total."
              }
            ].map((testimonial, i) => (
              <div key={i} className="bg-white p-8 rounded-2xl shadow-lg">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                    <Users className="w-6 h-6 text-blue-600" />
                  </div>
                  <div className="ml-4">
                    <h4 className="font-semibold">{testimonial.name}</h4>
                    <p className="text-gray-500 text-sm">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-600">"{testimonial.content}"</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-700 py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            Pronto para Começar seu Projeto?
          </h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Entre em contato agora e receba uma avaliação gratuita do seu projeto.
            Transforme sua ideia em realidade em tempo recorde.
          </p>
          <div className="flex gap-4 justify-center">
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Iniciar Projeto Agora
            </button>
            <button className="border-2 border-white text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition-colors">
              Agendar Reunião
            </button>
          </div>
        </div>
      </div>

      {/* FAQ */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Perguntas Frequentes</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Tire suas dúvidas sobre nosso processo de desenvolvimento
            </p>
          </div>

          <div className="max-w-3xl mx-auto space-y-6">
            {[
              {
                q: "Como funciona o processo de desenvolvimento?",
                a: "Iniciamos com uma reunião de briefing, definimos o escopo, desenvolvemos em sprints com feedbacks constantes até a entrega final."
              },
              {
                q: "Qual o prazo médio de entrega?",
                a: "Projetos de baixa complexidade são entregues em 1 semana, média em 2 semanas e alta em 3 semanas."
              },
              {
                q: "Como é feito o pagamento?",
                a: "Aceitamos cartão de crédito em até 12x, PIX e transferência bancária. O pagamento é dividido em 50% no início e 50% na entrega."
              },
              {
                q: "Vocês dão suporte após a entrega?",
                a: "Sim, oferecemos suporte técnico conforme o plano escolhido: 30 dias para baixa complexidade, 60 dias para média e 90 dias para alta."
              },
              {
                q: "Posso solicitar alterações durante o desenvolvimento?",
                a: "Sim, trabalhamos de forma iterativa e você pode solicitar ajustes durante todo o processo para garantir que o resultado final atenda suas expectativas."
              }
            ].map((faq, i) => (
              <div key={i} className="bg-gray-50 rounded-xl p-6 hover:bg-gray-100 transition-colors">
                <h3 className="font-semibold text-lg mb-2">{faq.q}</h3>
                <p className="text-gray-600">{faq.a}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export { LandingPage };
export default LandingPage;
