import * as handpose from '@tensorflow-models/handpose';

export class HandDetector {
  constructor() {
    this.model = null;
    this.isInitialized = false;
  }

  async initialize() {
    if (!this.isInitialized) {
      try {
        this.model = await handpose.load();
        this.isInitialized = true;
        console.log('Modelo de detecção de mão carregado');
      } catch (error) {
        console.error('Erro ao carregar modelo de detecção:', error);
        throw error;
      }
    }
  }

  async detectHand(imageElement) {
    if (!this.isInitialized) {
      await this.initialize();
    }

    try {
      const predictions = await this.model.estimateHands(imageElement);

      if (predictions.length > 0) {
        return {
          detected: true,
          landmarks: predictions[0].landmarks,
          boundingBox: this.calculateBoundingBox(predictions[0].landmarks)
        };
      }

      return {
        detected: false,
        message: 'Nenhuma mão detectada na imagem'
      };
    } catch (error) {
      console.error('Erro na detecção da mão:', error);
      throw error;
    }
  }

  calculateBoundingBox(landmarks) {
    const xs = landmarks.map(l => l[0]);
    const ys = landmarks.map(l => l[1]);

    return {
      minX: Math.min(...xs),
      maxX: Math.max(...xs),
      minY: Math.min(...ys),
      maxY: Math.max(...ys)
    };
  }
}

// Singleton instance
export const handDetector = new HandDetector();