import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true,
    debug: false
};

export const initFacebookPixel = () => {
    ReactPixel.init('307922972086398', null, options);
    ReactPixel.pageView();
};

export const FacebookPixel = {
    pageView: () => {
        ReactPixel.pageView();
    },
    track: (event, data) => {
        ReactPixel.track(event, data);
    }
}; 