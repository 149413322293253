import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  Button,
  Alert,
  AlertDescription
} from './ui-components';

const FacebookPixelTester = () => {
  const [pixelId, setPixelId] = useState('307922972086398'); // Atualizado o Pixel ID
  const [testCode, setTestCode] = useState('TEST61119');
  const [status, setStatus] = useState({ type: '', message: '' });

  useEffect(() => {
    if (!pixelId) return;

    // eslint-disable-next-line no-unused-expressions
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', pixelId, {
      external_id: 'test_user_id',
      test_event_code: testCode
    });

    window.fbq('track', 'PageView');

  }, [pixelId, testCode]);

  const testEvents = [
    {
      name: 'ViewContent',
      data: {
        content_name: 'Test Product',
        content_type: 'product',
        content_ids: ['TEST123'],
        value: 99.99,
        currency: 'BRL'
      }
    },
    {
      name: 'AddToCart',
      data: {
        content_ids: ['TEST123'],
        content_name: 'Test Product',
        value: 99.99,
        currency: 'BRL',
        contents: [{id: 'TEST123', quantity: 1}]
      }
    },
    {
      name: 'Purchase',
      data: {
        content_ids: ['TEST123'],
        content_name: 'Test Product',
        value: 99.99,
        currency: 'BRL',
        num_items: 1
      }
    }
  ];

  const triggerEvent = (eventData) => {
    try {
      if (!window.fbq) {
        throw new Error('Pixel não inicializado. Configure o Pixel ID primeiro.');
      }

      const eventConfig = {
        ...eventData.data,
        test_event_code: testCode
      };

      window.fbq('track', eventData.name, eventConfig);

      setStatus({
        type: 'success',
        message: `Evento ${eventData.name} disparado com sucesso!`
      });

      console.log('Event fired:', {
        name: eventData.name,
        data: eventConfig
      });

    } catch (error) {
      setStatus({
        type: 'error',
        message: error.message
      });
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Facebook Pixel Tester</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium">Pixel ID</label>
            <input
              type="text"
              value={pixelId}
              onChange={(e) => setPixelId(e.target.value)}
              placeholder="Digite seu Pixel ID"
              className="w-full p-2 border rounded"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium">Código de Teste</label>
            <input
              type="text"
              value={testCode}
              onChange={(e) => setTestCode(e.target.value)}
              placeholder="Código de teste"
              className="w-full p-2 border rounded"
            />
          </div>

          {status.message && (
            <Alert variant={status.type === 'error' ? 'destructive' : 'default'}>
              <AlertDescription>{status.message}</AlertDescription>
            </Alert>
          )}

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Eventos de Teste</h3>
            <div className="grid gap-4">
              {testEvents.map((event, index) => (
                <Button key={index} onClick={() => triggerEvent(event)}>
                  Disparar Evento {event.name}
                </Button>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default FacebookPixelTester; 