import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Leitura from './Components/Leitura/Leitura';
import Final from './Components/Final/Final';
import Code from './Components/Code/Code';
import MapaDesbloqueado from './Components/Mapa-desbloqueado/Mapa-desbloqueado';
import { PhotoProvider } from './contexts/PhotoContext';
import { ZodiacProvider } from './contexts/ZodiacContext';
import { initFacebookPixel, FacebookPixel } from './utils/FacebookPixel';
import MetaPixel from './Components/MetaPixel/MetaPixel';
import FacebookPixelTester from './Components/FacebookPixelTester/FacebookPixelTester';
import Leiturafast from './Components/Leiturafast/Leiturafast';
import './App.css';


function App() {
  useEffect(() => {
    initFacebookPixel();
  }, []);

  return (
    <Router>
      <ZodiacProvider>
        <PhotoProvider>
          <div className="App">
            <MetaPixel />
            <Routes>
              <Route path="/" element={
                <div className="min-h-screen bg-slate-800 flex flex-col items-center justify-center">
                  <h1 className="text-3xl text-amber-100 font-serif mb-8">
                    Bem-vindo ao Mapa do Destino
                  </h1>
                  <a
                    href="/leitura"
                    className="bg-teal-400 hover:bg-teal-500 text-white font-medium rounded-full px-8 py-4"
                  >
                    Começar Leitura
                  </a>
                </div>
              } />
              <Route path="/leitura" element={<Leitura />} />
              <Route path="/final" element={<Final />} />
              <Route path="/code" element={<Code />} />
              <Route path="/mapa-desbloqueado" element={<MapaDesbloqueado />} />
              <Route path="/facebook-pixel-tester" element={<FacebookPixelTester />} />
              <Route path="/leiturafast" element={<Leiturafast />} />
            </Routes>
          </div>
        </PhotoProvider>
      </ZodiacProvider>
    </Router>
  );
}

export default App;
