import React from 'react';

const HandLandmarks = ({ landmarks }) => {
  if (!landmarks) {
    console.log('Sem landmarks para renderizar');
    return null;
  }

  console.log('Renderizando landmarks:', landmarks);

  const fingerConnections = [
    [0, 1], [1, 2], [2, 3], [3, 4],
    [0, 5], [5, 6], [6, 7], [7, 8],
    [5, 9], [9, 10], [10, 11], [11, 12],
    [9, 13], [13, 14], [14, 15], [15, 16],
    [13, 17], [17, 18], [18, 19], [19, 20],
    [0, 5], [5, 9], [9, 13], [13, 17]
  ];

  // Obter dimensões do container
  const containerWidth = window.innerWidth;
  const containerHeight = window.innerHeight;

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none'
      }}
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
    >
      {fingerConnections.map(([start, end], index) => {
        const startPoint = landmarks[start];
        const endPoint = landmarks[end];

        if (!startPoint || !endPoint) return null;

        return (
          <line
            key={`line-${index}`}
            x1={startPoint.x * containerWidth}
            y1={startPoint.y * containerHeight}
            x2={endPoint.x * containerWidth}
            y2={endPoint.y * containerHeight}
            stroke="#40E0D0"
            strokeWidth="2"
            opacity="0.8"
          />
        );
      })}

      {landmarks.map((point, index) => (
        <circle
          key={`point-${index}`}
          cx={point.x * containerWidth}
          cy={point.y * containerHeight}
          r="4"
          fill="#40E0D0"
          opacity="0.8"
        />
      ))}
    </svg>
  );
};

export default HandLandmarks; 