import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5eead4',
    },
    background: {
      paper: '#1e293b',
      default: '#0f172a'
    },
    text: {
      primary: '#fff',
      secondary: '#94a3b8'
    }
  }
});

const DatePicker = React.forwardRef(({ className, onChange, ...props }, ref) => {
  const [value, setValue] = React.useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue) {
      onChange?.({
        month: newValue.format('MMMM').toLowerCase(),
        day: newValue.format('D'),
        year: newValue.format('YYYY')
      });
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <MuiDatePicker
          ref={ref}
          value={value}
          onChange={handleChange}
          className={className}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              variant: "outlined",
              fullWidth: true,
              sx: {
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(30, 41, 59, 0.5)',
                  backdropFilter: 'blur(8px)',
                  '& fieldset': {
                    borderColor: 'rgba(51, 65, 85, 0.5)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(51, 65, 85, 0.8)',
                  },
                }
              }
            },
          }}
          {...props}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
});

export { DatePicker };